import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f18f4b8 = () => interopDefault(import('../pages/cashier/index.vue' /* webpackChunkName: "pages/cashier/index" */))
const _366a0cba = () => interopDefault(import('../pages/detail.vue' /* webpackChunkName: "pages/detail" */))
const _5808ea46 = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _03ea0025 = () => interopDefault(import('../pages/favorite-games/index.vue' /* webpackChunkName: "pages/favorite-games/index" */))
const _2d491c23 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _9da3dcbc = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _6bb2cda3 = () => interopDefault(import('../pages/hold-balance/index.vue' /* webpackChunkName: "pages/hold-balance/index" */))
const _331fa244 = () => interopDefault(import('../pages/leaderboard/index.vue' /* webpackChunkName: "pages/leaderboard/index" */))
const _58823707 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f8ac70da = () => interopDefault(import('../pages/my-bonus/index.vue' /* webpackChunkName: "pages/my-bonus/index" */))
const _857069d6 = () => interopDefault(import('../pages/no-support.vue' /* webpackChunkName: "pages/no-support" */))
const _10d14db4 = () => interopDefault(import('../pages/not-found.vue' /* webpackChunkName: "pages/not-found" */))
const _2b27c468 = () => interopDefault(import('../pages/payment-methods/index.vue' /* webpackChunkName: "pages/payment-methods/index" */))
const _5f1972ee = () => interopDefault(import('../pages/play-game.vue' /* webpackChunkName: "pages/play-game" */))
const _ccbb13f8 = () => interopDefault(import('../pages/promotion/index.vue' /* webpackChunkName: "pages/promotion/index" */))
const _c632b906 = () => interopDefault(import('../pages/recently/index.vue' /* webpackChunkName: "pages/recently/index" */))
const _73f1b3c0 = () => interopDefault(import('../pages/refer-friend/index.vue' /* webpackChunkName: "pages/refer-friend/index" */))
const _07ce27b2 = () => interopDefault(import('../pages/referral/index.vue' /* webpackChunkName: "pages/referral/index" */))
const _d0da9f16 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _067e06ee = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _2bf39d8c = () => interopDefault(import('../pages/download/android.vue' /* webpackChunkName: "pages/download/android" */))
const _68713550 = () => interopDefault(import('../pages/download/ios.vue' /* webpackChunkName: "pages/download/ios" */))
const _5ee1045f = () => interopDefault(import('../pages/history/game.vue' /* webpackChunkName: "pages/history/game" */))
const _41382e01 = () => interopDefault(import('../pages/history/transaction.vue' /* webpackChunkName: "pages/history/transaction" */))
const _0c641bde = () => interopDefault(import('../pages/payment-methods/add-bank.vue' /* webpackChunkName: "pages/payment-methods/add-bank" */))
const _1f8734d3 = () => interopDefault(import('../pages/payment-methods/add-crypto-wallet.vue' /* webpackChunkName: "pages/payment-methods/add-crypto-wallet" */))
const _40a25d1e = () => interopDefault(import('../pages/player/banks/index.vue' /* webpackChunkName: "pages/player/banks/index" */))
const _28b0f07f = () => interopDefault(import('../pages/player/bet-history/index.vue' /* webpackChunkName: "pages/player/bet-history/index" */))
const _f36ad438 = () => interopDefault(import('../pages/player/deposits/index.vue' /* webpackChunkName: "pages/player/deposits/index" */))
const _4987e0db = () => interopDefault(import('../pages/player/messages/index.vue' /* webpackChunkName: "pages/player/messages/index" */))
const _2f325a66 = () => interopDefault(import('../pages/player/profile.vue' /* webpackChunkName: "pages/player/profile" */))
const _a1d7d784 = () => interopDefault(import('../pages/player/wallets.vue' /* webpackChunkName: "pages/player/wallets" */))
const _66585805 = () => interopDefault(import('../pages/player/withdrawals/index.vue' /* webpackChunkName: "pages/player/withdrawals/index" */))
const _1fa9fed1 = () => interopDefault(import('../pages/promotion/bonus.vue' /* webpackChunkName: "pages/promotion/bonus" */))
const _4c5ef2b5 = () => interopDefault(import('../pages/providers/games/index.vue' /* webpackChunkName: "pages/providers/games/index" */))
const _3ab44280 = () => interopDefault(import('../pages/player/deposits/apply.vue' /* webpackChunkName: "pages/player/deposits/apply" */))
const _1a71f66e = () => interopDefault(import('../pages/player/deposits/bank-transfer.vue' /* webpackChunkName: "pages/player/deposits/bank-transfer" */))
const _4e9b3058 = () => interopDefault(import('../pages/player/deposits/crypto-payment.vue' /* webpackChunkName: "pages/player/deposits/crypto-payment" */))
const _85ddaa2c = () => interopDefault(import('../pages/player/deposits/online-payment.vue' /* webpackChunkName: "pages/player/deposits/online-payment" */))
const _3506f190 = () => interopDefault(import('../pages/player/deposits/quick-payment.vue' /* webpackChunkName: "pages/player/deposits/quick-payment" */))
const _1ac204a1 = () => interopDefault(import('../pages/player/withdrawals/apply.vue' /* webpackChunkName: "pages/player/withdrawals/apply" */))
const _7499e459 = () => interopDefault(import('../pages/player/withdrawals/bank-withdrawal.vue' /* webpackChunkName: "pages/player/withdrawals/bank-withdrawal" */))
const _33391718 = () => interopDefault(import('../pages/player/withdrawals/crypto-withdrawal.vue' /* webpackChunkName: "pages/player/withdrawals/crypto-withdrawal" */))
const _e24ad820 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e8fdbc6 = () => interopDefault(import('../pages/payment-methods/edit/bank/_id.vue' /* webpackChunkName: "pages/payment-methods/edit/bank/_id" */))
const _c45b88ea = () => interopDefault(import('../pages/payment-methods/edit/crypto/_id.vue' /* webpackChunkName: "pages/payment-methods/edit/crypto/_id" */))
const _4d041e5d = () => interopDefault(import('../pages/providers/games/_id.vue' /* webpackChunkName: "pages/providers/games/_id" */))
const _2e7e0755 = () => interopDefault(import('../pages/Information/_id.vue' /* webpackChunkName: "pages/Information/_id" */))
const _189e296c = () => interopDefault(import('../pages/promotion/_id.vue' /* webpackChunkName: "pages/promotion/_id" */))
const _65dd766b = () => interopDefault(import('../pages/providers/_id.vue' /* webpackChunkName: "pages/providers/_id" */))
const _176f31f8 = () => interopDefault(import('../pages/articles/_title/_slug.vue' /* webpackChunkName: "pages/articles/_title/_slug" */))
const _499d5c3c = () => interopDefault(import('../pages/_type/_id.vue' /* webpackChunkName: "pages/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cashier",
    component: _5f18f4b8,
    name: "cashier"
  }, {
    path: "/detail",
    component: _366a0cba,
    name: "detail"
  }, {
    path: "/download",
    component: _5808ea46,
    name: "download"
  }, {
    path: "/favorite-games",
    component: _03ea0025,
    name: "favorite-games"
  }, {
    path: "/forgot-password",
    component: _2d491c23,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _9da3dcbc,
    name: "games"
  }, {
    path: "/hold-balance",
    component: _6bb2cda3,
    name: "hold-balance"
  }, {
    path: "/leaderboard",
    component: _331fa244,
    name: "leaderboard"
  }, {
    path: "/login",
    component: _58823707,
    name: "login"
  }, {
    path: "/my-bonus",
    component: _f8ac70da,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _857069d6,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _10d14db4,
    name: "not-found"
  }, {
    path: "/payment-methods",
    component: _2b27c468,
    name: "payment-methods"
  }, {
    path: "/play-game",
    component: _5f1972ee,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _ccbb13f8,
    name: "promotion"
  }, {
    path: "/recently",
    component: _c632b906,
    name: "recently"
  }, {
    path: "/refer-friend",
    component: _73f1b3c0,
    name: "refer-friend"
  }, {
    path: "/referral",
    component: _07ce27b2,
    name: "referral"
  }, {
    path: "/register",
    component: _d0da9f16,
    name: "register"
  }, {
    path: "/reset-password",
    component: _067e06ee,
    name: "reset-password"
  }, {
    path: "/download/android",
    component: _2bf39d8c,
    name: "download-android"
  }, {
    path: "/download/ios",
    component: _68713550,
    name: "download-ios"
  }, {
    path: "/history/game",
    component: _5ee1045f,
    name: "history-game"
  }, {
    path: "/history/transaction",
    component: _41382e01,
    name: "history-transaction"
  }, {
    path: "/payment-methods/add-bank",
    component: _0c641bde,
    name: "payment-methods-add-bank"
  }, {
    path: "/payment-methods/add-crypto-wallet",
    component: _1f8734d3,
    name: "payment-methods-add-crypto-wallet"
  }, {
    path: "/player/banks",
    component: _40a25d1e,
    name: "player-banks"
  }, {
    path: "/player/bet-history",
    component: _28b0f07f,
    name: "player-bet-history"
  }, {
    path: "/player/deposits",
    component: _f36ad438,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _4987e0db,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _2f325a66,
    name: "player-profile"
  }, {
    path: "/player/wallets",
    component: _a1d7d784,
    name: "player-wallets"
  }, {
    path: "/player/withdrawals",
    component: _66585805,
    name: "player-withdrawals"
  }, {
    path: "/promotion/bonus",
    component: _1fa9fed1,
    name: "promotion-bonus"
  }, {
    path: "/providers/games",
    component: _4c5ef2b5,
    name: "providers-games"
  }, {
    path: "/player/deposits/apply",
    component: _3ab44280,
    name: "player-deposits-apply"
  }, {
    path: "/player/deposits/bank-transfer",
    component: _1a71f66e,
    name: "player-deposits-bank-transfer"
  }, {
    path: "/player/deposits/crypto-payment",
    component: _4e9b3058,
    name: "player-deposits-crypto-payment"
  }, {
    path: "/player/deposits/online-payment",
    component: _85ddaa2c,
    name: "player-deposits-online-payment"
  }, {
    path: "/player/deposits/quick-payment",
    component: _3506f190,
    name: "player-deposits-quick-payment"
  }, {
    path: "/player/withdrawals/apply",
    component: _1ac204a1,
    name: "player-withdrawals-apply"
  }, {
    path: "/player/withdrawals/bank-withdrawal",
    component: _7499e459,
    name: "player-withdrawals-bank-withdrawal"
  }, {
    path: "/player/withdrawals/crypto-withdrawal",
    component: _33391718,
    name: "player-withdrawals-crypto-withdrawal"
  }, {
    path: "/",
    component: _e24ad820,
    name: "index"
  }, {
    path: "/payment-methods/edit/bank/:id?",
    component: _1e8fdbc6,
    name: "payment-methods-edit-bank-id"
  }, {
    path: "/payment-methods/edit/crypto/:id?",
    component: _c45b88ea,
    name: "payment-methods-edit-crypto-id"
  }, {
    path: "/providers/games/:id",
    component: _4d041e5d,
    name: "providers-games-id"
  }, {
    path: "/Information/:id?",
    component: _2e7e0755,
    name: "Information-id"
  }, {
    path: "/promotion/:id",
    component: _189e296c,
    name: "promotion-id"
  }, {
    path: "/providers/:id?",
    component: _65dd766b,
    name: "providers-id"
  }, {
    path: "/articles/:title?/:slug?",
    component: _176f31f8,
    name: "articles-title-slug"
  }, {
    path: "/:type/:id?",
    component: _499d5c3c,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
